import React, { Component } from "react"
import "./style.scss"

class Weather extends Component {
    constructor() {
        super()
        this.state = {
            latitude: null,
            longitude: null,
            temperature: null,
            description: null,
            appId: "4119dfca25e96bf1f10f35a975835f6c"
        }
    }

    getLocation() {
        if (this.state.latitude === null && this.state.longitude === null) {
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    this.setState({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    })
                }.bind(this)
            )
        }
    }

    async getWeather() {
        if (
            this.state.temperature === null ||
            this.state.description === null
        ) {
            if (this.state.latitude && this.state.longitude) {
              const url = `https://cors-anywhere.herokuapp.com/http://api.openweathermap.org/data/2.5/weather?lat=${this.state.latitude}&lon=${this.state.longitude}&units=Imperial&appid=${this.state.appId}`
              response = await fetch(url)
              if (response.ok) {
                  let json = await response.json()
                  this.setState({
                    temperature: json.main.temp.toFixed(0),
                    description: json.weather[0].description
                  })
            }
        }
    }
}
    componentDidMount() {
        // this.getLocation()
        // this.getWeather()
    }

    render() {
        return (
            <div className="weather-container">
                <div className="row">
                    <div id="weather-description" className="inline">
                        {this.state.description}
                    </div>
                    <span className="inline">&nbsp; &mdash; &nbsp;</span>
                    <div id="temp" className="inline">
                        {this.state.temperature}{" "}
                        {this.state.temperature && <span>&#176;f</span>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Weather
