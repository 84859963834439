import React from 'react';
import Start from "./components/index"

function App() {
    return (
        <div className="App">
            <Start />
        </div>
    );
}

export default App;