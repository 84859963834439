import React, { Component } from "react"
import "./style.scss"
import bookmarks from "../../data/bookmarks.json"
import searchEngines from "../../data/searchEngines.json"

const bookmarkKeys = Object.keys(bookmarks)
const searchEnginesKeys = Object.keys(searchEngines)
const isUrl = new RegExp(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
)

class Search extends Component {
    constructor() {
        super()
        this.nameInput = React.createRef()
        this.state = {
            currentText: "",
            searchMod: false,
            searchEngine: searchEngines.main.title,
            bookmark: false,
            key: false,
            background: false,
            color: false,
            url: searchEngines.main.url,
            searching: false,
            possibleKeys: [],
            allKeys: [],
            allQueries: [],
            helper: false
        }
        this.focusInput = this.focusInput.bind(this)
        this.resetSearch = this.resetSearch.bind(this)
        this.keyPressed = this.keyPressed.bind(this)
    }

    focusInput() {
      this.nameInput.current.focus()
    }

    inputChange(text) {
        let currentText = text.target.value.trim().toLowerCase()

        let key = text.target.value
            .trim()
            .substr(0, 2)
            .toLowerCase()

        this.setState({
            currentText: currentText,
            key: key
        })
        
        this.checkSearchEngines(currentText, key)
    }

    keyPressed(e) {
        if (e.keyCode === 13) {
            if (this.state.currentText.length > 0) {
            if (this.state.searchMod) {
                if (this.state.bookmark) {
                    this.search(this.state.url)
                } else {
                    this.search(
                        this.state.url +
                            this.state.currentText
                                .substr(this.state.key.length)
                                .trim()
                    )
                }
            } else if (isUrl.test(this.state.currentText)) {
                this.setState({
                    searchEngine: "url"
                })
                this.search(this.state.currentText)
            } else {
                this.search(this.state.url + this.state.currentText)
            }
            this.resetSearch()
        }
        }
        if (e.keyCode === 32) {
          this.focusInput() 
        }
    }

    search(url) {
        if (!url.match(/^https?:\/\//i)) {
            url = "http://" + url
        }
        window.open(url, "_self", 'noopener,noreferrer')
        this.setState({
            searching: true
        })
    }

    checkSearchEngines(text, key) {
        this.possibleKeys(text)
        if (key in searchEngines) {
            this.setState({
                searchMod: "searchEngine",
                searchEngine: searchEngines[key].title,
                url: searchEngines[key].url,
                background: searchEngines[key].background,
                color: searchEngines[key].color,
                key: key
            })
        } else if (text.trim().toLowerCase() in searchEngines) {
            key = text.trim().toLowerCase()
            this.setState({
                searchMod: "searchEngine",
                searchEngine: searchEngines[key].title,
                url: searchEngines[key].url,
                background: searchEngines[key].background,
                color: searchEngines[key].color,
                key: key
            })
        } else {
            this.checkBookmarks(text)
        }
    }

    checkBookmarks(text) {
        text = text.trim().toLowerCase()
        if (text in bookmarks) {
            this.setState({
                searchMod: "bookmark",
                searchEngine: bookmarks[text].title,
                bookmark: bookmarks[text].title,
                url: bookmarks[text].url,
                background: bookmarks[text].background,
                color: bookmarks[text].color,
            })
        } else {
            this.setState({
                searchMod: false,
                searchEngine: searchEngines.main.title,
                background: false,
                bookmark: false,
                color: false,
                key: false,
                url: searchEngines.main.url,
            })
        }
    }

    possibleKeys(text) {
        text = text.trim().toLowerCase()
        const keyList = bookmarkKeys.concat(searchEnginesKeys)
        const charNumber = text.length

        if (charNumber >= 1) {
            const possibleKeysArray = keyList.filter(
                key => key.substr(0, charNumber) === text
            ) 

            let possibleKeys = []
            possibleKeysArray.forEach(key => {
                possibleKeys = [...possibleKeys,
                    searchEngines[key]
                        ? [key, searchEngines[key].title]
                        : [key, bookmarks[key].title]
                ]
            })
            if (possibleKeys.length === 1 && text === possibleKeys[0][0]) {
                this.setState({
                    possibleKeys: []
                })
            } else {
                this.setState({
                    possibleKeys: possibleKeys
                })
            }
        } else {
            this.setState({
                possibleKeys: []
            })
        }
    }

    resetSearch() {
        setTimeout(function() {
            this.setState({
                currentText: "",
                searchMod: false,
                searchEngine: searchEngines.main.title,
                bookmark: false,
                key: false,
                background: false,
                color: false,
                url: searchEngines.main.url,
                searching: false,
                possibleKeys: [],
            })
            document.querySelector('#search-field').value = ""
        }.bind(this), 15000);
    }

    componentDidMount() {
        this.focusInput() 
        document.addEventListener("keypress", this.keyPressed)

        const keyList = bookmarkKeys
        const qList = searchEnginesKeys
        const allKeys = keyList.map(key => [key, bookmarks[key].title])
        const allQueries = qList.map(key => [key, searchEngines[key].title])

        this.setState({
            allKeys: allKeys,
            allQueries: allQueries
        })
    }

    render() {
        let fieldStyle,
            modeStyle = {}
        if (this.state.background && this.state.color) {
            fieldStyle = {
                background: "linear-gradient" + this.state.background,
                color: "white"
            }
            modeStyle = {
                color: this.state.color
            }
        }
        let searchClass = ""
        if (this.state.searching) {
            searchClass = "move-off-right"
        }

        return (
            <div id="inline-search">
                <input
                    autoComplete="off"
                    className={searchClass}
                    id="search-field"
                    type="text"
                    name="search-field"
                    onChange={this.inputChange.bind(this)}
                    onKeyUp={this.keyPressed.bind(this)}
                    style={fieldStyle}
                    ref={this.nameInput}
                />
                <div id="search-mode" className={searchClass} style={modeStyle}>
                    {this.state.searchEngine} <span className="loading" />
                </div>

                {this.state.helper &&
                <div id="search-options" className={searchClass + " options"}>
                    {this.state.possibleKeys.map(key => (
                        <div className="options__option" key={key} >
                            <span className="options__option__key">
                                {" "}
                                {key[0]}{" "}
                            </span>
                            <span className="options__option__title">
                                {" "}
                                {key[1]} {key[0].includes(":") ? "search" : ""}
                            </span>
                        </div>
                    ))}
                </div>
                }

                <div id="spacer"></div>

                {false &&
                    <>
                <div id="group">
                    <div id="group-label"> search </div>
                    <div id="search-options" className={searchClass + " options"}>
                        {this.state.allQueries.map(key => (
                            <div className="options__option" key={key} >
                                <span className="options__option__key">
                                    {" "}
                                    {key[0]}{" "}
                                </span>
                                <span className="options__option__title">
                                    {" "}
                                    {key[1]} {key[0].includes(":") ? "search" : ""}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>

                <div id="group">
                    <div id="group-label"> redirect </div>
                    <div id="search-options" className={searchClass + " options"}>
                        {this.state.allKeys.map(key => (
                            <div className="options__option" key={key} >
                                <span className="options__option__key">
                                    {" "}
                                    {key[0]}{" "}
                                </span>
                                <span className="options__option__title">
                                    {" "}
                                    {key[1]} {key[0].includes(":") ? "search" : ""}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                    </>
                }
            </div>
        )
    }
}

export default Search
